/**
 * ---------------------------------------------
 * mixin untuk invoice
 * ---------------------------------------------
 */
export const invoiceMixin = {
  methods: {
    mx_color(status) {
      let color = "warning";
      switch (status.toLowerCase()) {
        case "paid":
          color = "success";
          break;

        case "unpaid":
          color = "danger";
          break;
      }
      return color;
    },

    /**
     * --------------------------------------------
     * get warna / color text status invoice
     * --------------------------------------------
     */
    mx_text_color(status) {
      return "text-" + this.mx_color(status);
    }
  }
};
