<template>
  <div
    class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
  >
    <div class="kt-portlet">
      <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="kt-invoice-1">
          <div class="kt-invoice__head py-sm-4">
            <div class="kt-invoice__container">
              <div class="kt-invoice__brand">
                <div class="my-auto">
                  <h1 class="kt-invoice__title">
                    <div v-if="loading">
                      <v-skeleton-loader
                        type="paragraph"
                        width="300"
                      ></v-skeleton-loader>
                    </div>
                    <div v-else>
                      <span
                        :class="mx_text_color(row.status)"
                        class="font-weight-bold text-uppercase"
                      >
                        {{ row.status }}
                      </span>
                      <br />
                      <span id="no-inv">INV #{{ row.id }}</span> &nbsp;

                      <button
                        v-if="row.status === 'Unpaid'"
                        class="btn btn-success d-print-none btn-sm"
                        @click="get_redirect_whmcs(row.id)"
                        style="cursor: pointer"
                      >
                        CLICK HERE TO PAY
                      </button>
                    </div>
                    <hr class="d-block d-sm-none" />
                  </h1>
                </div>

                <div class="kt-invoice__logo">
                  <b-link :to="{ path: '/dashboard' }"
                    ><img
                      sv-inline
                      src="assets/rw/svg/rumahweb.svg"
                      style="width: 13rem"
                  /></b-link>
                  <span class="kt-invoice__desc">
                    <span>CV. Rumahweb Indonesia </span>
                    <span
                      >Jl. Lempongsari 39 C Sleman, DI Yogyakarta 55581</span
                    >
                  </span>
                </div>
              </div>
              <hr />

              <!-- invoice info -->
              <b-row class="pt-4">
                <b-col sm="3" cols="6">
                  <p class="font-weight-bold">INVOICE DATE</p>
                  <p v-if="loading">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </p>
                  <p v-else>
                    <b>{{ row.date }}</b>
                  </p>
                </b-col>
                <b-col sm="3" cols="6">
                  <p class="font-weight-bold">INVOICE NO</p>

                  <p v-if="loading">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </p>
                  <p v-else>
                    <b>#{{ row.id }}</b>
                  </p>
                </b-col>
                <b-col sm="6">
                  <p class="font-weight-bold">INVOICE TO</p>

                  <p v-if="loading">
                    <v-skeleton-loader type="sentences"></v-skeleton-loader>
                  </p>
                  <p v-else>
                    <b>{{ row.client.name }}</b> <br />
                    {{ row.client.alamat }}
                  </p>
                </b-col>
              </b-row>
              <!-- end invoice info -->
            </div>
          </div>

          <!-- body -->
          <div class="kt-invoice__body">
            <div class="kt-invoice__container">
              <div v-if="loading">
                <v-skeleton-loader type="sentences@6"></v-skeleton-loader>
              </div>
              <div class="table-responsive" v-else>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th class="font-weight-bold text-center">Description</th>
                      <th class="text-center font-weight-bold">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in row.item" :key="index">
                      <td>
                        {{ product.description }}
                      </td>
                      <td style="width: 20%">Rp {{ product.amount }}</td>
                    </tr>

                    <!-- about money :v -->
                    <tr class="bg-light">
                      <td class="font-weight-bold text-right">Sub Total</td>
                      <td>Rp {{ row.subtotal }}</td>
                    </tr>
                    <tr class="bg-light">
                      <td class="font-weight-bold text-right">10.00% PPN</td>
                      <td>Rp {{ row.ppn }}</td>
                    </tr>
                    <tr class="bg-light">
                      <td class="font-weight-bold text-right">Credit</td>
                      <td>Rp {{ row.credit }}</td>
                    </tr>
                    <tr class="bg-light">
                      <td class="font-weight-bold text-right">Total</td>
                      <td>Rp {{ row.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- action -->
          <div class="kt-invoice__actions">
            <div class="kt-invoice__container">
              <b-link
                :to="{ path: '/invoice' }"
                class="btn btn-secondary btn-brand"
                ><i class="fa fa-angle-double-left"></i> Back to all
                invoice</b-link
              >
              <button class="btn btn-brand btn-bold" @click="download()">
                <i class="fa fa-download"></i> Download Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { invoiceMixin } from "@/common/mixins/invoice/invoice-mixin.js";
import {
  breadcrumb,
  httpCode,
  loading,
  ApiURL,
  util,
  notif
} from "@/common/mixins/general.js";

export default {
  name: "invoice-single",
  mixins: [breadcrumb, httpCode, loading, ApiURL, invoiceMixin, util, notif],
  props: {
    /**
     * -------------------------------------
     * props id invoice from param path url
     * -------------------------------------
     */
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      row: {},
      loading: true,
      success: false
    };
  },
  methods: {
    get_invoice(id) {
      this.mx_loading();
      ApiService.setHeader();
      ApiService.get(this.api.invoice.row, id)
        .then(({ data }) => {
          /**
           * -------------------------------------
           * set breadcrumb
           * -------------------------------------
           */
          this.set_breadcrumb([
            { title: "Invoice", route: "invoice" },
            { title: "INV #" + this.id, route: "invoice/" + this.id },
            { title: data.data.status }
          ]);

          this.loading = false;
          this.row = data.data;
          this.success = true;
        })
        .catch(({ response }) => {
          // this.mx_toast(response.data.message, response.status, {variant:"danger"})
          this.mx_swal(response.data.message);
          this.$router.push({ path: "/dashboard" });
          setTimeout(() => {}, 10000);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    },

    /**
     * ------------------------------------------
     * request redirect ke ipg
     * ------------------------------------------
     */
    get_redirect_whmcs(id) {
      this.mx_loading();
      this.xhr_tools
        .get(this.api.tools.redirect.index, "?file=ipg&id=" + id)
        .then(({ data }) => {
          this.mx_redirect(data.data.url, false);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    },

    /**
     * ------------------------------------------
     * request download pdf
     * ------------------------------------------
     */
    download: function() {
      this.mx_loading();
      this.xhr_tools
        .get(this.api.tools.redirect.index, `?file=genpdf&id=${this.id}`)
        .then(({ data }) => {
          this.mx_redirect(data.data.url, false);
        })
        .finally(() => {
          this.mx_loading(false);
        });
    }
  },

  watch: {
    $route(to) {
      this.get_invoice(to.params.id);
    }
  },

  mounted() {
    this.get_invoice(this.id);
  }
};
</script>

<style scoped>
@import url("css/invoice-1.css");
.kt-invoice-1 .kt-invoice__head .kt-invoice__brand .kt-invoice__title {
  color: #646c9a;
  font-weight: 400;
  font-size: 2rem;
}
.kt-invoice-1
  .kt-invoice__head
  .kt-invoice__brand
  .kt-invoice__logo
  .kt-invoice__desc {
  color: #646c9a;
}
.kt-invoice-1 .kt-invoice__body table tbody tr td {
  font-weight: 300;
  border-bottom: 0.1rem solid #ebedf2;
  font-size: 13px;
  padding: 0.75rem;
}
.kt-invoice-1 .kt-invoice__body table tbody tr td:last-child {
  color: #646c9a;
  font-weight: 400;
}
.kt-invoice-1 .kt-invoice__body table tbody tr:first-child td {
}
</style>
